<template>
  <!-- 待学习 -->
  <div class="learningCourses">
    <van-nav-bar :title="studyTitle" left-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight"
      :fixed="true" style="height: 1.21875rem" />
    <div class="learningContainer">
      <!-- pdf内置播放器 -->
      <!-- <pdfView :pdf="pdf"></pdfView> -->
      <div class="videoDiv" style="
          width: 100%;
          height: 6.1867rem;
          position: fixed;
          left: 0;
          top: 1.21875rem;
          z-index: 999;
        ">
        <video width="100%" controls controlslist="nodownload" style="height: 6.1867rem; background: #000"
          v-if="videoTagStatus" poster="@/assets/img/video_fmt.jpg">
          <source :src="videoSrc" type="video/mp4" />
          {{ $t("learningCourses.CourseTxt2") }}
        </video>
        <zplayer v-if="videoStatus" ref="zplayer" :opt="playerOptions" :firstUpLoad="firstUpLoad" @ended="ended"
          @changeUrl="addSignIn" @timeupdate="timeUpdate" @firstplay="firstplay" @videopause="videopause"
          @videoplay="videoplay" @videoabort="videoabort" @videoplaying="videoplaying"></zplayer>
        <div v-if="pdfStatus" class="pdfClass">
          <a href="javascript:;" @click="visitPdf">
            <div class="pdfDiv">
              <div class="pdfContent">
                <svg-icon icon-class="pdf" v-if="wjhd == 'pdf'"></svg-icon>
                <svg-icon icon-class="ppt" v-if="wjhd == 'ppt' || wjhd == 'ptx'"></svg-icon>
                <svg-icon icon-class="doc" v-if="wjhd == 'doc' || wjhd == 'ocx'"></svg-icon>
                <svg-icon icon-class="xlsx" v-if="wjhd == 'xls' || wjhd == 'lsx'"></svg-icon>
                <p>{{ $t("learningCourses.CourseTxt3") }}</p>
              </div>
            </div>
          </a>
        </div>
        <div v-if="ifmUrl" class="pdfClass">
          <a href="javascript:;" @click="visitLink">
            <div class="pdfDiv">
              <div class="pdfContent">
                <svg-icon icon-class="link"></svg-icon>
                <p>{{ $t("learningCourses.CourseTxt11") }}</p>
              </div>
            </div>
          </a>
        </div>
        <div v-if="audioStatus" style="
            width: 100%;
            height: 6.1867rem;
            background: #000;
            display: flex;
            justify-content: center;
            align-items: center;
          ">
          <audio ref="audioRefDom" controlslist="nodownload" :src="audioSrc" controls="controls"
            style="max-width: 100%"></audio>
        </div>
      </div>
      <div class="tabsDiv" style="margin-top: 7.40545rem">
        <van-search style="border-bottom: 1px solid #eee;" v-model="searchVal" placeholder="请输入搜索关键词" @input="onSearch"
          class="fefefefef" />
        <van-tabs v-model="active" color="#2B8DF0" swipeable animated>
          <van-tab :title="$t('learningCourses.CourseTxt12')">
            <van-list v-model="loading" :finished="finished" :finished-text="$t('learningCourses.CourseTxt5')"
              @load="onLoad">
              <div v-for="(item, index) in list" :key="index">
                <h2 v-if="item && item.length > 0">{{ item[0].stagename }}</h2>
                <van-cell class="CoursesList" @click="changeCourseware(val, index, key)"
                  :class="{ activeCls: val.active }" v-for="(val, key) in item" v-show="item && item.length > 0"
                  :key="key">
                  <div class="infoExamIcon" v-if="val.isfinish">
                    <img src="@/assets/img/trained.png" alt="" />
                  </div>
                  <div class="leftbox">
                    <img v-if="val.cw_bg_url != ''" v-lazy="val.cw_bg_url" />
                    <img v-else-if="val.cwtype == '链接' && !val.cw_bg_url" src="@/assets/img/toLearnpic_pdf.png"
                      alt="" />
                    <img v-else-if="val.cwtype == '视频' && !val.cw_bg_url" src="@/assets/img/toLearnpic_shipin.png"
                      alt="" />
                    <img v-else-if="val.cwtype == '文档' && !val.cw_bg_url" src="@/assets/img/toLearnpic_pdf.png"
                      alt="" />
                    <img v-else-if="val.cwtype == '语音' && !val.cw_bg_url" src="@/assets/img/toLearnpic_yinpin.png"
                      alt="" />
                    <div class="kjlx">
                      {{ val.cwtype }}
                    </div>
                  </div>
                  <div class="info">
                    <div class="footer">
                      <span class="stageName"> {{ val.cwname }} </span>
                    </div>
                    <span class="description">{{ val.cwdesp }}</span>
                    <div class="top">
                      <div class="right">
                        <span class="length">{{ val.studymin
                          }}{{ $t("learningCourses.CourseTxt10") }}</span>
                      </div>
                    </div>
                  </div>
                </van-cell>
              </div>
            </van-list>
          </van-tab>
          <van-tab :title="$t('learningCourses.CourseTxt6')">
            <van-cell class="examList">
              <div class="info">
                <div class="footer">
                  <span class="courseName">{{ studyData.coursename }}</span>
                  <div class="right">
                    <span class="score">{{ studyData.coursecredit
                      }}{{ $t("learningCourses.CourseTxt7") }}</span>
                  </div>
                </div>
                <span class="lecturerName">{{ $t("learningCourses.CourseTxt8")
                  }}{{ studyData.teacher_empname }}</span>
                <div class="footer">
                  <span class="date">
                    {{ studyData.studybt }} - {{ studyData.studyet }}</span>
                  <div class="right">
                    <span class="duration">{{ studyData.coursehour
                      }}{{ $t("learningCourses.CourseTxt9") }}</span>
                  </div>
                </div>
              </div>
            </van-cell>
            <ul class="descriptionUl">
              <li>
                {{ studyData.coursedesp }}
              </li>
            </ul>
          </van-tab>
        </van-tabs>
      </div>
      <van-button  type="info" block @click="kaishiKaoShi" v-if="finish_jump_test == false?true:finish_jump_test && isfinish">{{ jump_btn_caption }}</van-button>
    </div>
    <div class="linkContainer" v-if="isLink">
      <iframeLink :ifmUrl="ifmUrl" :clickStatus="clickStatus"> </iframeLink>
    </div>
    <van-dialog v-model="show" :width="364" :beforeClose="beforeClose" :confirm-button-text="$t('module.confirm')"
      class="timerCapClass" :showConfirmButton="false">
      <div class="slide_box" v-if="whichVerify == 1">
        <slide-verify ref="slideblock" :slider-text="text" :accuracy="accuracy" :w="364" :h="200" :imgs="verifyImgs"
          @again="onAgain" @success="onSuccess" @fail="onFail" @refresh="onRefresh"></slide-verify>
      </div>
      <div class="checkPic_box" v-else-if="whichVerify == 2">
        <div class="title_box">
          <span class="desc">{{ picGroup[whichPicGroup - 1].title }}</span>
          <div class="btn_box">
            <van-button type="default" icon="replay" title="刷新验证" size="mini" @click="refreshPicGroup"></van-button>
            <van-button type="info" size="mini" @click="getPicGroup">确定</van-button>
          </div>
        </div>
        <div class="pic_box">
          <van-checkbox-group v-model="checkPicResult">
            <van-checkbox :name="idx + 1" v-for="(it, idx) in 6" :key="idx">
              <div class="coverBox" :class="checkPicResult.includes(idx + 1) ? 'checked_coverBox' : ''"></div>
              <van-image fit="contain"
                :src="require('@assets/img/' + picGroup[whichPicGroup - 1].picName + (idx + 1) + '.png')" />
            </van-checkbox>
          </van-checkbox-group>
        </div>
      </div>
      <div v-else class="show_identify">
        <div class="canvas_box">
          <Identify :identifyCode="identifyCode"></Identify>
        </div>
        <div class="refresh_row">
          <span @click="refreshCode">看不清楚？点击换一张</span>
        </div>
        <div class="desc_row">
          <span>请输入图片中的内容（若为数学运算的请输入计算结果）</span>
        </div>
        <div class="answer_row">
          <input class="an_input" type="text" v-model="inputCode">
          <van-button type="info" size="mini" @click="getAnswers">确定</van-button>
        </div>
      </div>
      <!-- <imageVerification ref="refImageVerification" @getIdentifyCodesFun="getIdentifyCodesFun" :show="show"></imageVerification> -->
    </van-dialog>

  </div>
</template>

<script>
import {
  getStepWareInfo,
  saveWatchLogStart,
  saveWatchLogEnd,
  getVideoLastPos,
  checkWareFinish,
  saveWatchLog,
  getStudyCourseInfo
} from "@api/wxpx.js";

import imageVerification from "@components/imageVerification";
import dragVerification from "@components/dragVerification";

const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import pdfView from "@components/pdfView/index.vue";
import zplayer from "./zPlayer.vue";
import iframeLink from "./link.vue";
import Identify from './identify'
import { Toast } from "vant";
import { setData, getData, _throttle, createGguid } from "@/utils";
export default {
  components: {
    pdfView,
    zplayer,
    iframeLink,
    imageVerification,
    dragVerification,
    Identify
  },
  data () {
    return {
      userInfo,
      videoSrc: "",
      active: 2,
      isLoading: false,
      list: [],
      loading: false,
      finished: false,
      studyData: {},
      videoStatus: false,
      pdfStatus: false,
      pdfUrl: "",
      wjhd: "",
      audioStatus: false,
      audioSrc: "",
      pdf: "",
      playerOptions: {
        controllBarMode: "click",
      },
      videoAutoid: 0,
      timer: null,
      guid: "",
      changeBeforeId: 0, //   组件切换前的id
      nowplaytime: 0, // 当前播放的时间总长 主要用于解决 视频切换的问题
      returnTime: 0,
      videoTagStatus: false,
      pdfId: 0,
      linkId: 0,
      audioId: 0,
      audioPlayer: null,
      firstUpLoad: false,
      studyTitle: "",
      isLink: false,
      ifmUrl: "",
      mautoid: this.$route.query.mautoid,
      clickStatus: "",
      dataIndex: 0,
      dataKey: 0,
      show: false,
      identifyCode: "",
      testIndex: 1,
      identifyTimer: null,
      curTime: 0,
      curTimeArr: [],
      firsCurtTime: 0,
      check_online: false,
      // --------------滑动验证参数
      showSlideVerify: false, // 滑动验证弹窗
      verifyImgs: [
        require("@/assets/img/slideVerify_01.png"),
        require("@/assets/img/slideVerify_02.png"),
        require("@/assets/img/slideVerify_03.png"),
        require("@/assets/img/slideVerify_04.png"),
        require("@/assets/img/slideVerify_05.png"),
        require("@/assets/img/slideVerify_06.png"),
        require("@/assets/img/slideVerify_07.png"),
        require("@/assets/img/slideVerify_08.png"),
        require("@/assets/img/slideVerify_09.png"),
        require("@/assets/img/slideVerify_10.png"),
        require("@/assets/img/slideVerify_11.png"),
        require("@/assets/img/slideVerify_12.png"),
        require("@/assets/img/slideVerify_13.png"),
        require("@/assets/img/slideVerify_14.png"),
        require("@/assets/img/slideVerify_15.png"),
      ],
      verifyMsg: "",
      text: "拖动滑块完成拼图",
      accuracy: 2, // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      need_slider_code: false, // 每次登录需要验证码
      vertify_mode: 0, // 验证方式 0滑块验证，1文字验证
      // ------------
      yzmyzcgl: false,
      checkSpecMin: 0,
      check_mode: "",
      firstRecodTime: 0,
      searchVal: "",
      baseList: [],
      // --- 图片选择验证
      whichVerify: 0,
      picGroup: [
        { title: '请选择最符合描述的图片：“舞龙”', picName: 'wuLong', answer: ['3', '5'] },
        { title: '请选择最符合描述的图片：“敦煌莫高窟飞像”', picName: 'moGaoKu', answer: ['1', '3'] },
        { title: '请选择最符合描述的图片：“未来机器人”', picName: 'jiQiRen', answer: ['2', '3'] },
        { title: '请选择最符合描述的图片：“关公像”', picName: 'guangGong', answer: ['1', '4'] },
        { title: '请选择最符合描述的图片：“古风女孩”', picName: 'nvHai', answer: ['1', '5'] },
        { title: '请选择最符合描述的图片：“科技感马头琴”', picName: 'maTouQin', answer: ['2', '4'] },
        { title: '请选择最符合描述的图片：“摩托车头盔侧面”', picName: 'touKui', answer: ['2', '5'] },
        { title: '请选择最符合描述的图片：“勇士与猛虎”', picName: 'mengHu', answer: ['3', '4'] },
        { title: '请选择最符合描述的图片：“鱼”', picName: 'fish', answer: ['1', '2'] },
        { title: '请选择最符合描述的图片：“熊猫”', picName: 'panda', answer: ['5', '6'] },
        { title: '请选择最符合描述的图片：“一个厨房桌的红苹果”', picName: 'apple', answer: ['1', '2'] },
        { title: '请选择最符合描述的图片：“公园里的小桥”', picName: 'bridge', answer: ['1', '3'] },
        { title: '请选择最符合描述的图片：“一座有冰川的雪山”', picName: 'xueShan', answer: ['1', '4'] },
        { title: '请选择最符合描述的图片：“电力变压器”', picName: 'bianYaQi', answer: ['1', '5'] },
        { title: '请选择最符合描述的图片：“普洱熟茶饼”', picName: 'chaBing', answer: ['2', '3'] },
        { title: '请选择最符合描述的图片：“冰鸟冬日的极光”', picName: 'jiGuang', answer: ['2', '4'] },
        { title: '请选择最符合描述的图片：“长嘴铁皮壶”', picName: 'changZuiHu', answer: ['2', '5'] },
        { title: '请选择最符合描述的图片：“萨克斯”', picName: 'saxophone', answer: ['2', '6'] },
        { title: '请选择最符合描述的图片：“中药柜”', picName: 'zhongYao', answer: ['3', '4'] },
        { title: '请选择最符合描述的图片：“中国风的龙”', picName: 'Loong', answer: ['3', '5'] },
        { title: '请选择最符合描述的图片：“云南石林”', picName: 'shiLin', answer: ['3', '6'] },
        { title: '请选择最符合描述的图片：“简约风的工作头像”', picName: 'touXiang', answer: ['1', '5'] },
        { title: '请选择最符合描述的图片：“劳作的农民”', picName: 'nongMin', answer: ['2', '3'] },
        { title: '请选择最符合描述的图片：“液压泵”', picName: 'yeYaBeng', answer: ['2', '5'] }
      ],
      checkPicResult: [], // 选中的图片
      whichPicGroup: 1, // 随机的哪组图片
      // 输入验证
      whichInput: 0,
      identifyCode: '',
      identifyCodes: '123456789ABCDEFGHGKMNPQRSTUVWXYZabcdefghgkmnpqrstuvwxyz', // 验证码规则
      answerCode: '',
      inputCode: '',
      finish_jump_test:false,
      jump_btn_caption:'',
      paperid:0,
      teststyle:'',
      isfinish:false,
    };
  },
  watch: {
    show (n) {
      if (n)
      {
        this.whichVerify = this.getRandomNumber(3)
        this.whichPicGroup = this.getRandomNumber(24)
        if (this.whichVerify == 3)
        {
          this.refreshCode()
        }
      } else
      {
        this.checkPicResult = []
      }
    }
  },
  mounted () {
    const _this = this;
    // 首次加载 缓存guid
    if (window.localStorage.getItem("learningCoursesDataKey" + this.mautoid))
    {
      _this.dataKey = window.localStorage.getItem(
        "learningCoursesDataKey" + this.mautoid
      );
    }
    if (
      window.localStorage.getItem("learningCoursesDataIndex" + this.mautoid)
    )
    {
      _this.dataIndex = window.localStorage.getItem(
        "learningCoursesDataIndex" + this.mautoid
      );
    }

    this.$nextTick(() => {
      this.getStudyCourseInfoFun();
      window.addEventListener("visibilitychange", function () {
        //部分手机可以获取到
        if (document.hidden)
        {
          _this.listenerClose();
        }
      });
      window.addEventListener("pagehide", () => {
        _this.listenerClose();
      });
      window.addEventListener("popstate", function (e) {
        _this.listenerClose();
      });
      window.addEventListener("onunload", _this.listenerWindow);
    });
  },

  methods: {
    getStudyCourseInfoFun () {
      getStudyCourseInfo({
        mautoid:this.mautoid,
        empid:this.userInfo.empid,
        ispts:this.studyData.ispts
      }).then(res=>{
        if(res && res.data && res.data.length > 0){
          const obj = res.data[0];
          this.finish_jump_test = obj.finish_jump_test;
          this.jump_btn_caption = obj.jump_btn_caption;
          this.paperid = obj.paperid;
          this.teststyle = obj.teststyle;
          this.isfinish = obj.isfinish;
          // finish_jump_test : 是否学习完毕才允许跳转考试
          // jump_btn_caption : 跳转按钮标题
          // paperid : 试卷id
          // teststyle : 考试类别：模拟、正式、补考 
          // isfinish : 是否全部课程学习完毕
        }
        console.log(res,'jofhwekjfhwekj')
      })
    },
    // 输入验证确认
    getAnswers () {
      let accord = (this.answerCode + '').toLowerCase() == this.inputCode.toLowerCase()
      if (accord)
      {
        this.show = false;
        this.inputCode = ''
        setTimeout(() => {
          this.$refs.zplayer._videoPlay();
        }, 50)
      } else
      {
        Toast.fail('验证错误，请重试！')
        this.inputCode = ''
        this.refreshCode()
      }
    },
    // 生成十以内四个数字加法运算
    generateMathQuiz () {
      const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]; // 数字池
      const randomIndexes = []; // 用于存储随机选择的索引
      let question = '';
      let answer = 0;
      // 随机选择三个不重复的数字索引
      while (randomIndexes.length < 4)
      {
        const randomIndex = Math.floor(Math.random() * numbers.length);
        if (randomIndexes.indexOf(randomIndex) === -1)
        {
          randomIndexes.push(randomIndex);
        }
      }
      // 获取随机选择的三个数
      const num1 = numbers[randomIndexes[0]];
      const num2 = numbers[randomIndexes[1]];
      const num3 = numbers[randomIndexes[2]];
      const num4 = numbers[randomIndexes[3]];
      // 随机选择是加减法
      // const operator = Math.random() < 0.5 ? '+' : '-';
      const operator = '+' // 只算加法
      // 生成问题和答案
      if (operator === '+')
      {
        question = `${num1} ${operator} ${num2} ${operator} ${num3} ${operator} ${num4} = ?`;
        answer = num1 + num2 + num3 + num4
      } else
      {
        question = `${num1} ${operator} ${num2} = ?`; // 假设只有两个数进行减法
        answer = num1 - num2
      }
      // console.log(question, 'questionquestion', answer);
      this.answerCode = answer
      return { question, answer };
    },
    // 切换数字字母验证码
    refreshCode () {
      this.identifyCode = ''
      this.whichInput = this.getRandomNumber(2)
      if (this.whichInput == 1)
      {
        const quiz = this.generateMathQuiz();
        this.identifyCode = quiz.question
      } else
      {
        this.makeCode(this.identifyCodes, 4)
      }
      // console.log(this.answerCode, 'answerCode')
    },
    // 生成随机数字字母验证码
    makeCode (o, l) {
      for (let i = 0; i < l; i++)
      {
        this.identifyCode += this.identifyCodes[
          Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
        ]
      }
      this.answerCode = this.identifyCode
    },
    // 选择图片验证时确定
    getPicGroup () {
      // console.log(this.checkPicResult, 'checkPicResultcheckPicResult', this.checkPicResult.join(''));
      const res = this.checkPicResult.join('') || ''
      let accord = false
      for (let i = 0; i < this.picGroup[this.whichPicGroup - 1].answer.length; i++)
      {
        const ele = this.picGroup[this.whichPicGroup - 1].answer[i]
        if (res.indexOf(ele) != -1)
        {
          accord = true
        }
      }
      if (accord)
      {
        this.show = false;
        setTimeout(() => {
          this.$refs.zplayer._videoPlay();
        }, 50)
      } else
      {
        Toast.fail('验证错误，请重试！')
      }
    },
    // 选择图片验证时刷新
    refreshPicGroup () {
      this.checkPicResult = []
      this.whichPicGroup = this.getRandomNumber(24)
    },
    // 生成随机整数
    getRandomNumber (num) {
      return Math.floor(Math.random() * num) + 1;
    },
    onSearch (val) {
      if (val)
      {
        if (this.baseList.length > 0)
        {
          for (let i = 0; i < this.baseList.length; i++)
          {
            this.list[i] = this.baseList[i].filter((item) => {
              return item.cwname.indexOf(val) >= 0
            })
          }
        }
      } else
      {
        this.list = JSON.parse(JSON.stringify(this.baseList));
      }
      console.log(val, this.baseList, this.list, 'dwedwedewed')
    },
    onAgain () {
      console.log("检测到非人为操作的哦！");
      this.verifyMsg = "try again";
      // 刷新
      this.$refs.slideblock.reset();
    },
    // 滑动验证成功
    onSuccess (times) {
      this.verifyMsg = `验证成功！耗时${(times / 1000).toFixed(1)}s`;
      // this.yzmyzcgl = true;
      this.show = false;
      // this.yzmyzcgl = false;
      this.verifyMsg = "";
      setTimeout(() => {
        this.$refs.slideblock.reset();
        this.$refs.zplayer._videoPlay();
      }, 50)

    },
    getIdentifyCodesFun (identifyCode) {
      this.identifyCode = identifyCode;
    },
    onFail () {
      this.verifyMsg = "";
    },
    onRefresh () {
      this.verifyMsg = "";
    },
    listenerClose () {
      let _this = this;
      var img = new Image();
      let baseUrl = window.BASE_URL
        ? window.BASE_URL
        : "https://v8.hyclound.cn:8080/v8api";
      let apiUrl = "/api/wxpx/saveWatchLog_End";
      let data =
        "?empid=" +
        _this.userInfo.empid +
        "&mautoid=" +
        _this.$route.query.mautoid +
        "&cw_autoid=" +
        _this.videoAutoid +
        "&guid=" +
        _this.guid +
        "&videotimes=" +
        _this.nowplaytime +
        "&lastpos=" +
        getData(`xfyktVideoTime_${_this.videoAutoid}`) +
        "&username=" +
        _this.userInfo.username +
        "&isfinish=0";
      img.style.display = "none";
      img.src = baseUrl + apiUrl + data; // 服务端处理接口
      document.body.appendChild(img);
      localStorage.setItem("upload_time", new Date().getTime());
    },
    listenerWindow (event) {
      this.listenerClose();
      event.preventDefault();
      event.returnValue = "是否离开？";
      return true;
    },
    // 当视频播放结束后 会自动调用暂停
    ended (options) {
      console.log(options, "播放完成！");
      clearInterval(this.identifyTimer);
      this.identifyTimer = null;
      this.recordVideoInfo(this.videoAutoid, this.nowplaytime, 1);
      // 还应该调用一个接口
    },
    // 记录时间
    // time是秒
    // 需要记录的视频id 可以是上一个播放的视频
    // status是状态 1是视频播放完整
    recordVideoInfo (id, time, status) {
      // 把时间转换为分钟 且只保留2位小数 不做四舍五入
      let minute = Math.floor((time / 60) * 100) / 100;
      if (id && minute && getData(`xfyktVideoTime_${id}`))
      {
        saveWatchLogEnd({
          empid: this.userInfo.empid,
          mautoid: this.$route.query.mautoid,
          cw_autoid: id,
          lastpos: getData(`xfyktVideoTime_${id}`),
          guid: this.guid,
          videotimes: minute,
          username: this.userInfo.username,
          isfinish: status,
          ispts: this.studyData.ispts,
        }).then((res) => {
          console.log("记录视频信息的变化");
          clearInterval(this.identifyTimer);
          this.identifyTimer = null;
        });
      }
    },
    beforeClose (action, done) {
      // console.log(this.identifyCode,this.$refs.refImageVerification.enterCode,'验证码');
      // this.show = false;
      if (this.yzmyzcgl)
      {
        this.show = false;
        this.yzmyzcgl = false;
        this.verifyMsg = "";

        done()
        setTimeout(() => {
          this.$refs.slideblock.reset();
        }, 50)
        this.$refs.zplayer._videoPlay();
      } else
      {
        done(false)
      }
    },
    // 播放停止 如视频之间的切换
    videoabort () {
      if (this.changeBeforeId && this.nowplaytime)
      {
        this.recordVideoInfo(this.changeBeforeId, this.nowplaytime, 0);
      }
    },
    videoplaying (time) {
      // console.log(time,'videoplaying')
      if (this.curTime > 0)
      {
        var timer = null;
        let timeridx = 1;
        clearInterval(this.identifyTimer);
        this.identifyTimer = null;
        this.identifyTimer = setInterval(() => {
          timeridx++
          if (timeridx >= this.curTime)
          {
            clearInterval(this.identifyTimer);
            this.identifyTimer = null;
            this.$refs.zplayer._videoPause();
            this.show = true;
            timeridx = 1;
          }
        }, 1000)
      }

    },
    // 播放暂停
    videopause (time) {
      clearInterval(this.identifyTimer);
      this.identifyTimer = null;
      this.recordVideoInfo(this.videoAutoid, time, 0);
    },
    // 播放开始
    videoplay (time) {
      this.nowplaytime = time;
      if (!time)
      {
        alert("网络延迟！当前播放状态，视频无法记录！");
        time = 0;
      }
      // 把时间转换为分钟 且只保留2位小数 不做四舍五入 videoAutoid
      let minute = Math.floor((time / 60) * 100) / 100;
      saveWatchLogStart({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        cw_autoid: this.videoAutoid,
        guid: this.guid,
        videotimes: minute,
        username: this.userInfo.username,
        ispts: this.studyData.ispts,
      })
        .then((res) => {
          console.log("记录播放开始的状态");
        })
        .catch((err) => {
          alert("接口出错了：saveWatchLogStart");
        });
    },
    // 视频的时间更新
    timeUpdate (time) {

      if (this.curTimeArr && this.curTimeArr.length > 0 && this.check_online && this.check_mode == '自定义时间点')
      {
        if (this.curTimeArr.includes(parseInt(time)) && (parseInt(time) - parseInt(this.firsCurtTime)) > 1)
        {
          this.firsCurtTime = parseInt(time);
          this.$refs.zplayer._videoPause();
          this.show = true;
        }
      }
      if (this.check_mode == '固定间隔')
      {
        if (time >= (this.firstRecodTime + this.checkSpecMin * 60))
        {
          this.firstRecodTime = parseInt(time);
          this.$refs.zplayer._videoPause();
          this.show = true;
        }
      }
      // console.log(time, "time");
      // this.testIndex++
      // if (Math.floor() % 20 == 0) {
      //   // alert(1);
      //   this.$refs.zplayer._videoPause();
      //   // this.show = true;
      // }
      if (!time || this.timer) return false;
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.timer = null;
      }, 1000);
      // localStorage.setItem(`xfyktVideoTime_${id}`, time)
      if (Math.floor(time) % (window.video_save_pos_sec || 30) == 0)
      {
        this.recordVideoInfo(this.changeBeforeId, time, 0);
      }
      setData(`xfyktVideoTime_${this.videoAutoid}`, time);
    },
    getVideoLastPosTime (id, ispts) {
      getVideoLastPos({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        cw_autoid: id,
        username: this.userInfo.username,
        ispts: ispts
      }).then((res) => {
        this.returnTime = res.data[0].lastpos;
        if (!this.firstRecodTime)
        {
          this.firstRecodTime = this.returnTime || 0
        }
      });
    },
    async checkWareIsFinish (id, ispts) {
      let res = await checkWareFinish({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        cw_autoid: id,
        username: this.userInfo.username,
        ispts: ispts
      });
      return res.data[0].isfinish;
    },
    firstplay () {
      // 获取用户上次观看的时间点 然后更新进度
      this.setLastVideoTime();
    },
    // 设置用户上一次看到的进度
    setLastVideoTime () {
      if (this.returnTime)
      {
        setTimeout(() => {
          this.$refs.zplayer._setCurrentTime(
            Math.floor(Number(this.returnTime))
          );
        }, 100);
      } else
      {
        let res = this.findVideoLog(this.videoAutoid);
        setTimeout(() => {
          this.$refs.zplayer._setCurrentTime(Math.floor(Number(res)));
        }, 100);
      }
    },
    //找到该视频的记录
    findVideoLog (id) {
      return Number(getData(`xfyktVideoTime_${id}`)) || 0;
      // return Number(localStorage.getItem(`xfyktVideoTime_${id}`)) || 0
    },
    //添加签到的时间节点  当视频更换或者初始化的时候执行
    addSignIn (data) { },
    onClickLeft () {
      if (this.videoAutoid)
      {
        this.recordVideoInfo(this.videoAutoid, this.nowplaytime, 0);
      }
      if (this.clickStatus == "iframe")
      {
        this.clickStatus = "";
        this.isLink = false;
      } else
      {
        this.$router.push({
          path: '/toLearn',
          query: {
            ispts: this.studyData.ispts ? 1 : 0
          }
        })
      }
    },
    visitPdf () {
      saveWatchLog({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        cw_autoid: this.pdfId,
        username: this.userInfo.username,
        ispts: this.studyData.ispts,
      }).then((res) => {
        window.localStorage.setItem(
          "learningCoursesDataIndex" + this.mautoid,
          this.dataIndex
        );
        window.localStorage.setItem(
          "learningCoursesDataKey" + this.mautoid,
          this.dataKey
        );
        this.pdf = this.pdfUrl + "?time=" + new Date().getTime();
        if (this.pdfUrl.slice(-3) == 'pdf')
        {
          this.$router.push({
            path: "/pdfView",
            query: {
              pdf: encodeURIComponent(this.pdf),
              empid: this.userInfo.empid,
              mautoid: this.$route.query.mautoid,
              cw_autoid: this.pdfId,
              username: this.userInfo.username,
              guid: this.guid,
            },
          });
        } else
        {
          this.onLoad();
          var u = navigator.userAgent
          var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
          var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
          if (isAndroid)
          {
            //android终端
            window.open('https://view.officeapps.live.com/op/view.aspx?src=' + this.pdf)
          } else if (isiOS)
          {
            //ios终端
            window.location.href = 'https://view.officeapps.live.com/op/view.aspx?src=' + this.pdf
          } else
          {
            window.open('https://view.officeapps.live.com/op/view.aspx?src=' + this.pdf)
          }
        }


        console.log("记录pdf开始的状态");
      });
    },
    visitLink () {
      if (!this.ifmUrl)
      {
        Toast(this.$t("learningCourses.CourseTxt13"));
      }
      saveWatchLog({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        cw_autoid: this.linkId,
        username: this.userInfo.username,
        ispts: this.studyData.ispts,
      }).then((res) => {
        window.localStorage.setItem(
          "learningCoursesDataIndex" + this.mautoid,
          this.dataIndex
        );
        window.localStorage.setItem(
          "learningCoursesDataKey" + this.mautoid,
          this.dataKey
        );
        this.clickStatus = "iframe";
        this.isLink = true;
      });
    },
    audioPlay () {
      saveWatchLogStart({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        cw_autoid: this.audioId,
        guid: this.guid,
        videotimes: 0,
        username: this.userInfo.username,
        ispts: this.studyData.ispts,
      }).then((res) => {
        console.log("记录audio开始的状态");
      });
    },
    onClickRight () { },
    onLoad () {
      this.studyData = JSON.parse(
        window.sessionStorage.getItem("v8_study_data")
      );
      clearInterval(this.identifyTimer);
      this.identifyTimer = null;
      console.log(this.studyData, "studyData");
      getStepWareInfo({
        empid: this.userInfo.empid,
        mautoid: this.$route.query.mautoid,
        ispts: this.studyData.ispts,
      }).then((res) => {
        console.log(typeof res.data);

        this.ifmUrl = "";
        let rdata = res.data;
        // this.dataKey
        // activeCls
        //  _this.dataIndex
        //  _this.dataIndex
        rdata.forEach((item, idx) => {
          item.active = false;
        });
        let mapdata = this.arrayToMap(rdata);
        this.baseList = JSON.parse(JSON.stringify(this.mapToArray(mapdata)))
        this.list = this.mapToArray(mapdata);
        this.list[this.dataIndex][this.dataKey].active = true;

        let datacwtype = this.list[this.dataIndex][this.dataKey].cwtype;
        let ispts = this.list[this.dataIndex][this.dataKey].ispts;

        let dd = this.list[this.dataIndex][this.dataKey];
        this.check_online = dd.check_online;
        // this.curTimeArr = dd.check_second_list?dd.check_second_list.split(","):[]
        this.check_mode = dd.check_mode;
        if (dd.check_mode == '自定义时间点')
        {
          let arr = dd.check_second_list ? dd.check_second_list.split(",") : [];
          for (let i = 0; i < arr.length; i++)
          {
            arr[i] = arr[i] * 60
          }
          this.curTimeArr = arr;
        } else if (dd.check_mode == '固定间隔')
        {
          this.checkSpecMin = dd.check_spec_min
        }
        // console.log(ispts,'dddddddddddddddddddddddd')
        this.studyTitle = dd.cwname;
        if (datacwtype == "视频")
        {
          this.guid = createGguid();
          this.videoAutoid = dd.autoid;
          // this.videoTagStatus
          this.changeBeforeId = this.videoAutoid;
          this.checkWareIsFinish(this.videoAutoid, ispts).then((resss) => {
            if (resss)
            {
              // 播放完成
              this.videoStatus = false;
              setTimeout(() => {
                this.videoTagStatus = true;
                this.videoSrc = dd.cwurl;
              }, 100);
            } else
            {
              this.videoTagStatus = false;
              this.videoStatus = true;
              this.getVideoLastPosTime(this.videoAutoid, ispts);
              this.firstUpLoad = false;
              setTimeout(() => {
                this.videoSrc = dd.cwurl;
                this.firstUpLoad = true;
                this.$refs.zplayer.changeUrl(this.videoSrc);
              }, 100);
            }
          });
        } else if (datacwtype == "文档")
        {
          this.guid = createGguid();
          this.pdfUrl = dd.cwurl;
          this.wjhd = dd.cwurl ? dd.cwurl.slice(-3) : ""
          console.log(this.wjhd, 'this.wjhd')
          this.pdfId = dd.autoid;
          this.pdfStatus = true;
        } else if (datacwtype == "语音")
        {
          this.audioSrc = dd.cwurl;
          this.audioId = dd.autoid;
          this.audioStatus = true;
          setTimeout(() => {
            this.audioPlayer = this.$refs.audioRefDom;
            // 监听视频是否正在播放
            this.audioPlayer.addEventListener("play", () => {
              this.audioPlay();
            });
          }, 100);
        } else if (datacwtype == "链接")
        {
          this.linkId = dd.autoid;
          this.ifmUrl = dd.cwurl;
        }
        console.log(this.list, "loadingloading");

        this.loading = false;
        this.finished = true;
      });
    },
    arrayToMap (data) {
      //非数组或数据长度为0不处理
      if (!data instanceof Array || data.length == 0)
      {
        return {};
      }
      var map = {};
      for (var i = 0; i < data.length; i++)
      {
        // if (!data[i] instanceof Array || data.length < 2) {
        //   continue;
        // }
        let name = data[i].stagename;
        if (name != undefined)
        {
          if (map[name] == undefined)
          {
            map[name] = [];
          }
          map[name].push(data[i]);
        }
      }

      return map;
    },
    //把map转成数组
    mapToArray (data) {
      if (data == undefined)
      {
        return [];
      }
      let array = [];
      for (let p in data)
      {
        array.push(data[p]);
      }
      return array;
    },
    // 切换课件
    changeCourseware (val, index, key) {
      clearInterval(this.identifyTimer);
      this.identifyTimer = null;
      if (!val.cwurl)
      {
        Toast(this.$t("learningCourses.CourseTxt13"));
        return false;
      }
      this.check_online = val.check_online;
      this.check_mode = val.check_mode;
      if (val.check_mode == '自定义时间点')
      {
        let arr = val.check_second_list ? val.check_second_list.split(",") : [];
        for (let i = 0; i < arr.length; i++)
        {
          arr[i] = arr[i] * 60
        }
        this.curTimeArr = arr;
      } else if (val.check_mode == '固定间隔')
      {
        this.checkSpecMin = val.check_spec_min
      }

      this.studyTitle = val.cwname;
      this.ifmUrl = "";
      if (val.cwtype == "视频")
      {
        this.guid = createGguid();
        this.changeBeforeId = this.videoAutoid;
        this.pdfStatus = false;
        this.audioStatus = false;
        this.isLink = false;
        this.videoAutoid = val.autoid;
        this.recordVideoInfo(this.changeBeforeId, this.nowplaytime, 0);
        this.checkWareIsFinish(this.videoAutoid, val.ispts).then((resss) => {
          if (resss)
          {
            // 播放完成
            this.videoStatus = false;
            this.videoTagStatus = false;
            setTimeout(() => {
              this.videoTagStatus = true;
              this.videoSrc = val.cwurl;
            }, 100);
          } else
          {
            this.videoTagStatus = false;
            this.videoStatus = true;
            this.getVideoLastPosTime(this.videoAutoid, val.ispts);
            this.firstUpLoad = false;
            setTimeout(() => {
              this.videoSrc = val.cwurl;
              this.firstUpLoad = true;
              this.$refs.zplayer.changeUrl(this.videoSrc);
            }, 100);
          }
        });
      } else if (val.cwtype == "文档")
      {
        this.guid = createGguid();
        this.pdfStatus = false;
        this.videoStatus = false;
        this.audioStatus = false;
        this.videoTagStatus = false;
        this.isLink = false;
        this.pdfId = val.autoid;
        if (this.videoAutoid && this.nowplaytime)
        {
          // 如果之前播放视频
          this.recordVideoInfo(this.videoAutoid, this.nowplaytime, 0);
        }
        setTimeout(() => {
          this.pdfUrl = val.cwurl;
          this.wjhd = val.cwurl ? val.cwurl.slice(-3) : ""
          this.pdfStatus = true;
          this.dataIndex = index;
          this.dataKey = key;
        }, 100);
      } else if (val.cwtype == "语音")
      {
        if (this.videoAutoid && this.nowplaytime)
        {
          // 如果之前播放视频
          this.recordVideoInfo(this.videoAutoid, this.nowplaytime, 0);
        }
        this.pdfStatus = false;
        this.videoStatus = false;
        this.audioStatus = false;
        this.videoTagStatus = false;
        this.isLink = false;
        this.audioId = val.autoid;
        setTimeout(() => {
          this.audioSrc = val.cwurl;
          this.audioStatus = true;
          setTimeout(() => {
            this.audioPlayer = this.$refs.audioRefDom;
            // 监听视频是否正在播放
            this.audioPlayer.addEventListener("play", () => {
              this.audioPlay();
            });
          }, 100);
        }, 100);
      } else if (val.cwtype == "链接")
      {
        this.ifmUrl = val.cwurl;
        this.linkId = val.autoid;
        this.pdfStatus = false;
        this.videoStatus = false;
        this.audioStatus = false;
        this.videoTagStatus = false;
        this.clickStatus = "iframe";
        // this.isLink = true;
        this.dataIndex = index;
        this.dataKey = key;
      }

      this.list.forEach((item) => {
        item.forEach((vals) => {
          vals.active = false;
        });
      });
      val.active = true;
      // 如果有视频就改变video地址，没有视频就占位
    },
    kaishiKaoShi () {
      this.$router.push({
        path: "/examinInfo",
        query: { paperid: this.paperid, mautoid: this.mautoid, teststyle: this.teststyle,fromPath:"learningCourses" },
      });
    }
  },
};
</script>
<style lang="less" scoped>
.learningCourses {
  width: 100%;
  height: 100%;
  overflow: auto;

  .linkContainer {
    padding-top: 0;
    width: 100%;
    height: 1px;
  }

  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }

    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }

    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }

  .tabsDiv {
    ::v-deep .van-tab {
      font-size: 32px;
      font-family: Source Han Sans CN;
    }

    h2 {
      margin: 0;
      padding: 15px 28px;
      font-size: 32px;
      font-weight: 500;
      background: #f1f1f1;
    }

    .CoursesList {
      padding: 28px;

      .van-cell__value {
        position: unset !important;
        display: flex;

        .leftbox {
          width: 240px;
          height: 130px;
          margin-right: 18px;
          position: relative;
        }

        img {
          width: 240px;
          height: 130px;
        }

        .info {
          flex: 1;

          .description {
            color: #999999;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 36px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          .footer {
            display: flex;
            justify-content: space-between;
            // align-items: flex-end;
            align-items: baseline;

            .stageName {
              width: 78%;
              margin: 0;
              font-size: 28px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
            }

            .right {
              .length {
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 27px;
                color: #2b8df0;
              }
            }
          }

          .top {
            display: flex;
            justify-content: flex-end;
            color: #2b8df0;
            font-weight: bold;
          }
        }
      }
    }

    .examList {
      margin: 20px 0;

      .van-cell__value {
        display: flex;

        .info {
          flex: 1;

          .lecturerName {
            color: #999999;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }

          .footer {
            display: flex;
            justify-content: space-between;
            // align-items: flex-end;
            align-items: baseline;

            .courseName {
              width: 70%;
              margin: 0;
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              // line-height: 54px;
              color: #333333;
              // opacity: 1;
            }

            .date {
              color: #999999;
              font-size: 24px;
            }

            .right {
              .score {
                font-size: 32px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 27px;
                color: #2b8df0;
              }

              .duration {
                font-size: 32px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 27px;
                color: #333333;
              }
            }
          }
        }
      }
    }

    .descriptionUl {
      background: #fff;
      padding: 20px 30px;

      li {
        text-indent: 2em;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 54px;
        color: #333333;
        opacity: 1;
      }
    }
  }

  .commentList {
    .van-card:first-child {
      margin-top: 16px;
    }

    .van-card {
      background: #fff;

      .van-card__thumb {
        margin-right: 30px;
        width: 74px;
        height: 74px;

        img {
          width: 74px;
          height: 74px;
        }
      }

      .van-card__content {
        .name {
          display: flex;
          justify-content: flex-end;

          span:first-child {
            margin-right: auto;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #666666;
          }

          .svg_icon {
            display: flex;
            align-items: center;

            span {
              margin: 0 20px;
            }
          }
        }

        .van-card__desc {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }

        .content {
          padding-top: 20px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 48px;
          color: #333333;
          opacity: 1;
        }

        .imgs {
          ::v-deep .van-grid-item__content {
            padding: 15px 4px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .comments {
          height: 80px;
          background: #eceaea;
          opacity: 1;
          border-radius: 4px;
          display: flex;
          align-items: center;

          span {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 48px;
            opacity: 1;
          }

          span:first-child {
            padding-left: 20px;
            color: #2b8df0;
          }

          span:last-child {
            padding-left: 20px;
            color: #666666;
          }
        }
      }
    }
  }

  .entry_button {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;

    ::v-deep input {
      padding: 0 40px;
      height: 64px;
      background: #f4f4f4;
      border-radius: 44px;
    }
  }
}

.activeCls {
  background: #dee7f1 !important;
}

.kjlx {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 5px 10px;
  background: rgb(121, 0, 255, 0.8);
  color: #fff;
  font-size: 12px;
  border-radius: 5px 0 0 0;
}

.videoDiv {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ccc;
}

.pdfClass {
  width: 100%;
  height: 100%;

  a {
    width: 100%;
    height: 100%;
    display: block;
  }

  .pdfDiv {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pdfContent {
    text-align: center;

    p {
      margin: 0;
      width: 100%;
      text-align: center;
      font-size: 32px;
      margin-top: 20px;
    }
  }
}

.infoExamIcon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99999;
  width: 90px;

  img {
    width: 90px !important;
    height: auto !important;
  }
}

.svg-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.timerCapClass {
  /deep/.van-dialog__content {
    padding-top: 30px;

    // 选择图片验证
    .checkPic_box {
      padding: 0 20px;

      .title_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .desc {
          font-size: 24px;
        }

        .btn_box {
          display: flex;

          .van-button {
            width: 80px;
          }
        }
      }

      .pic_box {
        margin: 20px 0;

        .van-checkbox-group {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;

          .van-checkbox {
            position: relative;
            width: 32%;

            .van-checkbox__icon {
              position: absolute;
              top: 5px;
              right: 5px;
              z-index: 999;

              .van-icon {
                opacity: 0;
              }
            }

            .van-checkbox__icon--checked {
              .van-icon {
                opacity: unset !important;
              }
            }

            .van-checkbox__label {
              margin: 0;
              width: 100%;

              .van-image {
                width: 100%;
              }

              .coverBox {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background: #fff;
                z-index: 998;
                opacity: 0;
              }

              .checked_coverBox {
                opacity: 0.6 !important;
              }
            }
          }

          .van-checkbox:not(:nth-child(3)):not(:nth-child(6)) {
            margin: 0 1% 1% 0;
          }
        }
      }
    }

    // 计算验证
    .show_identify {
      padding: 0 20px;
      padding-bottom: 30px;

      .canvas_box {
        text-align: center;
      }

      .refresh_row {
        text-align: center;
        font-size: 24px;
        padding: 10px 0 20px 0;
        color: #396eb8;

        span {
          text-decoration: underline;
        }
      }

      .desc_row {
        font-size: 24px;
        text-align: center;
        padding-bottom: 20px;
      }

      .answer_row {
        display: flex;
        align-items: center;
        justify-content: center;

        .an_input {
          width: 50%;
          margin-right: 20px;
        }
      }
    }
  }

  border-radius: 0 !important;
}
</style>